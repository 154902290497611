import { Link } from "gatsby"
import React from "react"
import classNames from "classnames"

const Header = ({ header = "Every day", smallHeader, headerLink = "/" }) => {
  let heroClasses = classNames("hero-body", {
    "hero-body--less-padding": smallHeader,
  })
  return (
    <section className="hero">
      <div className={heroClasses}>
        <div className="container has-text-centered">
          <h1 className="title has-text-weight-semibolder">
            <Link to={headerLink}>
              <span className="has-text-grey">{header}</span>
            </Link>
          </h1>
          <h2 className="subtitle has-text-grey-dark">
            A photo a day for 365 days.
          </h2>
        </div>
      </div>
    </section>
  )
}

export default Header
